import { makeStyles, Theme } from '@material-ui/core';

export const useSearchStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '6rem',
    paddingRight: '6rem',
    paddingTop: '3.2rem',
    paddingBottom: '3.2rem',
    borderBottom: '0.4px solid #FFF',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 2.4rem',
    },
  },
  input: {
    width: '100%',
    border: 'none',
    color: '#707072',
    fontSize: '2.4rem',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: '3.6rem',
    wordWrap: 'break-word',
    backgroundColor: 'transparent',
    '&:placeholder': {
      color: '#707072',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      lineHeight: '3rem',
    },
  },
  arrow: {
    width: '3.2rem',
    height: '3.2rem',
    [theme.breakpoints.down('sm')]: {
      width: '2rem',
      height: '2rem',
    },
  },
}));
