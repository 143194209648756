import { createSlice } from '@reduxjs/toolkit';


interface homeStoreInterface {
  height: number,
  homeLeftActiveHidden: boolean;
  moveLeftActive: boolean;
}

const initialState: homeStoreInterface = {
  height: document.body.clientHeight,
  homeLeftActiveHidden: true,
  moveLeftActive: true,

};
export const homeStore = createSlice({
  name: 'homeStore',
  initialState,
  reducers: {
    setWinHeight(state, action) {
      state.height = action.payload;
    },
    setHomeLeftActiveHidden(state, action) {
      state.homeLeftActiveHidden = action.payload;
    },
    setMoveLeftActive(state, action) {
      state.moveLeftActive = action.payload;
    },
  },
});


const { setWinHeight, setHomeLeftActiveHidden, setMoveLeftActive } = homeStore.actions;
export { setWinHeight, setHomeLeftActiveHidden, setMoveLeftActive };
