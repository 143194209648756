import { makeStyles, Theme } from '@material-ui/core';

export const useNavStyle = makeStyles((theme: Theme) => ({
  root: {
    width: 'max-content',
    paddingRight: '2rem',
    display: 'flex',
    height: '46rem',
    flexFlow: 'column wrap',
    columnGap: '20rem',
    rowGap: '2.4rem',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'max-content',
      lineHeight: '3.6rem',
    },
  },
  link: {
    display: 'block',
    color: '#F5F5F5',
    fontSize: '4.8rem',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    lineHeight: '150%',
    wordWrap: 'break-word',
    '&:hover': {
      color: '#68FE60',
    },
    '&.active': {
      color: '#68FE60',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4rem',
      lineHeight: '3.6rem',
    },
  }
}));
