import { isPc, setIsPc } from './tool'

export const rem = () => {
  setIsPc();
  let size:number = (window.innerWidth / (isPc ? 1440 : 375)) * 10;
  document.documentElement.style.fontSize = size.toFixed(2) + 'px';
};

rem();
window.addEventListener('resize', rem);


