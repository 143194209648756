import classNames from 'classnames';
import { usePageNavStyle } from './usePageNavStyle';
import { Side } from 'modules/Home/components/Page2/components/Side';
// import { Card } from 'modules/Home/components/Card';
import { LinkIcon } from 'modules/Home/components/LinkIcon';
import { Search } from 'modules/layout/components/PageNav/components/Search';
import { Nav } from 'modules/layout/components/PageNav/components/Nav';
import { LinkTxt } from 'modules/layout/components/PageNav/components/LinkTxt';
// import { t } from 'modules/i18n/utils/intl';

interface PageNavProps {
  isHide: boolean;
  navClick: any;
}

export const PageNav = ({ isHide, navClick }: PageNavProps) => {
  const classes = usePageNavStyle();

  return (
    <div className={classNames(classes.root, isHide && 'hide')}>
      <Search />
      <div className={classes.wrapCard}>
        <Nav isHide={isHide} navClick={navClick} />
        {/* <Card title={t('home.cardTitleTips1')} content={t('home.cardContentTips1')} />
        <Card /> */}
        <LinkIcon />
      </div>
      <div className={classNames(classes.wrapLink)}>
        <LinkTxt />
      </div>
      <Side />
    </div>
  );
};
