import { makeStyles, Theme } from '@material-ui/core';

export const useLinkIconStyle = makeStyles((theme: Theme) => ({
  root: {
    width: 'max-content',
    gap: '.8rem',
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    width: '3.6rem',
    height: '3.6rem',
    margin: '1.4rem',
    transition: 'all 1s',
    '&:hover': {
      color: '#FFF',
      transform: 'scale(1.3)',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },
}));
