import classNames from 'classnames';
import { useLinkIconStyle } from './useLinkIconStyle';

import icon1 from 'assets/img/linkIcon/icon1.png';
import icon2 from 'assets/img/linkIcon/icon2.png';
// import icon3 from 'assets/img/linkIcon/icon3.png';
import icon4 from 'assets/img/linkIcon/icon4.png';
import icon5 from 'assets/img/linkIcon/icon5.png';
import icon6 from 'assets/img/linkIcon/icon6.png';
import icon7 from 'assets/img/linkIcon/icon7.png';
import {IconLink} from '../../../../assets/js/iconLink';

export const LinkIcon = () => {
  const list = [
    {
      url: IconLink.twitter,
      logo: icon1
    }, {
      url: IconLink.telegram,
      logo: icon2
    },
    // {
    //   url: IconLink.discord,
    //   logo: icon3
    // },
    {
      url: IconLink.medium,
      logo: icon4
    }, {
      url: IconLink.reddit,
      logo: icon5
    }, {
      url: IconLink.docs,
      logo: icon6
    }, {
      url: IconLink.channel,
      logo: icon7
    },
  ];
  const classes = useLinkIconStyle();
  return (
    <div className={classNames(classes.root, 'linkIcon')}>
      {
        list.map((d, i) => {
          return <a key={i} href={d.url} target='_black'><img className={classes.link} src={d.logo} alt="" /></a>
        })
      }
    </div>
  );
};
