export const RequestApi = {
  login: {
    auth: '/auth',
    // emailRegister:'/v1/emailRegister',
    // emailLogin:'/v1/emailLogin',
    // getGoogleOauthCodeUrl:'/v1/oauth/getGoogleOauthCodeUrl',
    // googleOauthLogin:'/v1/oauth/googleOauthLogin'
  },
  swap:{
    getBalance:'/chain/getBalance',
    getAddress:'/chain/getAddress',
    history:'/chain/getRecords',
  },
  search:{
    searchPairByType:'/v1/data/SearchPairByType',
    searchPairByTokenAddress:'/v1/data/searchPairByTokenAddress',
    pairInfos:'/v1/data/pairInfos',
    pairTxList:'/v1/data/pairTxList',
    searchPairTxListByAddress:'/v1/data/searchPairTxListByAddress',
    searchTxListByAddress:'/v1/data/searchTxListByAddress',
    searchTxListByAddressAndZone:'/v1/data/searchTxListByAddressAndZone'
  },
  subscribe: {
    subscribeEmail: '/subscribe/email',
  },
};
