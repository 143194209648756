import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_HOME = '/bridge';
export const SwapRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_HOME,
    generatePath: () => PATH_HOME,
  },
};

const LoadableSwapContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.Swap),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);


export function SwapRoutes() {
  return (
    <Route
      path={SwapRoutesConfig.Index.path}
      exact={true}
      component={LoadableSwapContainer}
    />
  );
}
