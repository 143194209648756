import { useSearchStyle } from './useSearchStyle';
import classNames from 'classnames';
import arrow from 'assets/img/icon/arrow.png';
import { t } from 'modules/i18n/utils/intl';

export const Search = () => {
  const classes = useSearchStyle();
  return (
    <div className={classNames(classes.root)}>
      <input className={classNames(classes.input)} placeholder={t('home.search')} type='text' />
      <img className={classNames(classes.arrow)} src={arrow} alt='' />
    </div>
  );
};
