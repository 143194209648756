import { ThemeProvider } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { getTheme } from 'modules/common/utils/getTheme';
import { Themes, CurrentTheme } from 'modules/themes/types';
// import { Header } from '../Header';
import { useMainLayoutStyles } from './MainLayoutStyles';
import { Box } from '@material-ui/core';
// import {Footer} from '../Footer';
import { useState } from 'react';
import { PageSide } from '../PageSide';
import { PageNav } from '../PageNav';

import {useSelector} from 'react-redux';
import { RootState } from '../../../../store';

export interface ILayoutProps {
  children?: React.ReactNode;
  headerTheme?: Themes;
}

export const MainLayout = ({
                             children,
                             headerTheme = CurrentTheme,
                           }: ILayoutProps) => {
  const classes = useMainLayoutStyles();
  const isDarkBg = headerTheme === Themes.dark;

  const [isHide, navClick] = useState<boolean>(true);
  const { location: { pathname } } = useSelector((store: RootState) => store.router);

  return (
    <ThemeProvider theme={getTheme(CurrentTheme)}>
      <Box className={
        classNames(
          classes.root,
          isDarkBg && 'dark',
        )}>
        {/*<Header />*/}
        <main className={classNames(classes.content)}>
          {children}
        </main>
        {
          pathname==='/home' && <div className={classNames('fit-out', 'wh-all', 'events-none')}>
            <PageNav isHide={isHide} navClick={navClick} />
            <PageSide isHide={isHide} navClick={navClick} />
          </div>
        }

        {/*<Footer/>*/}
      </Box>
    </ThemeProvider>
  );
};
