import { makeStyles, Theme } from '@material-ui/core/styles';

export const useMainLayoutStyles = makeStyles<Theme>(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  content: {
    // height: 'calc(100% - 70px)',
    // minHeight: '100%',
    overflow: 'hidden',
  },
}));
