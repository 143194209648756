import { Switch, Redirect } from 'react-router-dom';
import { MainLayout } from 'modules/layout/components/MainLayout';
import { PageNotFound } from 'modules/router/components/PageNotFound';
import { PageComingSoon } from 'modules/router/components/PageComingSoon';
import { CurrentTheme } from 'modules/themes/types';
import { HomeRoutes, HomeRoutesConfig } from 'modules/Home/Routes';
import { AnimateSwitch } from './modules/common/components/AnimateSwitch';
import { ProgressBar } from './modules/common/components/ProgressBar';
// import { MintRoutes, MintRoutesConfig } from './modules/Mint/Routes';
// import { PassRoutes, PassRoutesConfig } from './modules/Pass/Routes';
import { BountyRoutes, BountyRoutesConfig } from './modules/Bounty/Routes';
import {SwapRoutes, SwapRoutesConfig} from './modules/Swap/Routes';

export function Routes() {
  return (
    <MainLayout headerTheme={CurrentTheme}>
      <AnimateSwitch>
        <Switch>
          <ProgressBar
            exact
            path='/'
            render={() => <Redirect to={HomeRoutesConfig.Index.path} />}
          />
          <ProgressBar
            exact
            path={HomeRoutesConfig.Index.path}
            render={() => (
              <HomeRoutes />
            )}
          />
          {/*<ProgressBar*/}
          {/*  exact*/}
          {/*  path={MintRoutesConfig.Index.path}*/}
          {/*  render={() => (*/}
          {/*    <MintRoutes />*/}
          {/*  )}*/}
          {/*/>*/}
          {/*<ProgressBar*/}
          {/*  exact*/}
          {/*  path={PassRoutesConfig.Index.path}*/}
          {/*  render={() => (*/}
          {/*    <PassRoutes />*/}
          {/*  )}*/}
          {/*/>*/}
          <ProgressBar
            exact
            path={BountyRoutesConfig.Index.path}
            render={() => (
              <BountyRoutes />
            )}
          />
          <ProgressBar
            exact
            path={SwapRoutesConfig.Index.path}
            render={() => (
              <SwapRoutes />
            )}
          />

          <ProgressBar
            exact
            path={'/coming_soon'}
            render={() => (
              <PageComingSoon />
            )}
          />

          <ProgressBar
            render={() => (
              <PageNotFound />
            )}
          />
        </Switch>
      </AnimateSwitch>

    </MainLayout>
  );
}
