import { makeStyles, Theme } from '@material-ui/core';

export const usePageNavStyle = makeStyles((theme: Theme) => ({
  root: {
    pointerEvents: 'auto',
    position: 'fixed',
    top: '0',
    right: '0',
    width: 'calc(100% - 10rem)',
    height: '100%',
    overflowY: 'scroll',
    backgroundColor: '#1F1F21',
    zIndex: 9,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .linkIcon': {
      flexDirection: 'column',
    },
    '& .sideBox': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      top: '6rem',
      width: '100%',
      height: 'calc(100% - 6rem)',
      '& .linkSide': {
        display: 'none',
      },
      '& .sideBox': {
        position: 'initial',
        display: 'flex',
        flexShrink: 0,
      },
      '& .card': {
        display: 'none',
      },
    },
  },
  wrapCard: {
    height: '100%',
    paddingTop: '6rem',
    paddingLeft: '6rem',
    paddingRight: '6rem',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '3rem',
      paddingLeft: '2.4rem',
      paddingRight: '2.4rem',
    },
  },
  wrapLink: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '6rem',
    paddingLeft: '6rem',
    paddingRight: '7.4rem',
    marginBottom: '3.4rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '2.4rem',
      marginTop: '2.4rem',
      marginBottom: '2.5rem',
    },
  },
}));
