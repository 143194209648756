import { makeStyles, Theme } from '@material-ui/core';

export const useLinkTxtStyle = makeStyles((theme: Theme) => ({
  root: {
    width: 'max-content',
    columnGap: '3.2rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      columnGap: '1.2rem',
      rowGap: '0.4rem',
    },
  },
  link: {
    opacity: 0.70,
    color: 'white',
    fontSize: '1.4rem',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: '2.1rem',
    wordWrap: 'break-word',
    '&:hover': {
      color: '#FFF',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      lineHeight: '1.8rem',
    },
  },
  language: {
    cursor: 'pointer',
    width: '9.3rem',
    opacity: '0.7',
    position: 'relative',
    color: 'white',
    fontSize: '1.4rem',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: '2.1rem',
    wordWrap: 'break-word',
    '&:hover': {
      '& .pop': {
        display: 'block',
      },
      '& .down': {
        transform: 'rotate(180deg)',
      }
    },
    [theme.breakpoints.down('sm')]: {
      width: '7.9rem',
      fontSize: '1.2rem',
      lineHeight: '1.8rem',
    },
  },
  down: {
    width: '1.6rem',
    height: '1.6rem',
  },
  pop: {
    position: 'absolute',
    top: '100%',
    paddingTop: '.4rem',
    paddingBottom: '.4rem',
    display: 'none'
  }
}));
