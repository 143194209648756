export let isPc: boolean = window.innerWidth >= 768;

export const setIsPc = () => {
    isPc = window.innerWidth >= 768;
}

export const heightToTop = (ele: any) => {
    let root = document.body;
    let height = 0;
    do {
        height += ele.offsetTop;
        ele = ele.offsetParent;
    } while (ele !== root)
    return height;
};

export const stopScroll = () => {
    const mo = (e: any) => { e.preventDefault(); };
    document.body.style.overflow = 'hidden';
    document.addEventListener("touchmove", mo, false);
}

export const moveScroll = () => {
    const mo = (e: any) => { e.preventDefault(); };
    document.body.style.overflow = '';
    document.addEventListener("touchmove", mo, false);
}



